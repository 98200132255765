import React from 'react';
import useMeasure from 'react-use-measure';
import debounce from 'debounce';
import * as Types from '../../../declarations';
import { getUploadThumbPath } from '../../../lib/display';
import useWindowDimensions from '../../../lib/hooks/useWindowDimensions';
interface Props {
  pictures: Types.DB.Models.UploadAttributes[];
  size: string;
  altText: string;
  onImageClick?: (imageId: number) => void;
  isVertical?: boolean;
}
interface ScaledImageItem extends Types.DB.Models.UploadAttributes {
  scaledWidth: number;
  scaledHeight: number;
  ref: React.RefObject<HTMLImageElement>;
  index: number;
}
type Row = ScaledImageItem[];
const JustifiedGallery = ({
  pictures,
  size,
  altText,
  onImageClick
}:
// isVertical = false,
Props) => {
  if (!pictures) return <></>;
  const DEBOUNCE_RESIZE = 100;
  const [ref, bounds] = useMeasure({
    scroll: true,
    // don't mindlessly fire scroll events, but resize more responsively
    debounce: {
      scroll: 200,
      resize: DEBOUNCE_RESIZE
    }
  });
  const [rows, setRows] = React.useState<Row[]>([]);
  const [rowHeights, setRowHeights] = React.useState<number[]>([]);
  const refs: React.RefObject<HTMLImageElement>[] = pictures.map(() => React.createRef());
  const [debouncedWidth, setDebouncedWidth] = React.useState<number>(0);
  const {
    width
  } = useWindowDimensions();
  const dimensions = (dimensions: string): {
    width: number;
    height: number;
  } => {
    const [width, height] = dimensions.split('x').map(x => +x);
    return {
      width,
      height
    };
  };
  const calculateJustifiedImageGallery = () => {
    const holdingRows: Row[] = [];
    let rowInProgress: Row = [];
    const rowHeights: number[] = [];
    const marginSize = 8;
    // const marginSize = 0
    const scrollMargin = 0;
    let imagesInRow = 0;
    let runningWidth = 0;
    const smallestWidth = Math.min(bounds?.width - marginSize * 1 ?? 1000, debouncedWidth ?? 1000);
    // also minus 2*margin as the main column will have some padding
    const availableWidth = smallestWidth - scrollMargin; //- 2 * marginSize

    for (let resultNo = 0, added = 1; resultNo < pictures.length; resultNo++, added++) {
      // add image to current row under construction
      const result = pictures[resultNo];
      rowInProgress.push({
        ...result,
        scaledWidth: dimensions(result.dimensionsSmall).width,
        scaledHeight: dimensions(result.dimensionsSmall).height,
        ref: React.createRef(),
        index: resultNo
      });
      imagesInRow++;

      // get shortest in row
      // take the first height as a base
      let shortestImageInRowHeight = dimensions(rowInProgress[0].dimensionsSmall).height;
      for (let rowHeightCheck = 1; rowHeightCheck < rowInProgress.length; rowHeightCheck++) {
        if (dimensions(rowInProgress[rowHeightCheck].dimensionsSmall).height < shortestImageInRowHeight) {
          shortestImageInRowHeight = dimensions(rowInProgress[rowHeightCheck].dimensionsSmall).height;
        }
      }

      // scale each to that height
      runningWidth = 0;
      for (let scaleEachInRow = 0; scaleEachInRow < rowInProgress.length; scaleEachInRow++) {
        const scaleFactor = shortestImageInRowHeight / dimensions(rowInProgress[scaleEachInRow].dimensionsSmall).height;
        const scaledHeight = dimensions(rowInProgress[scaleEachInRow].dimensionsSmall).height * scaleFactor;
        const scaledWidth = dimensions(rowInProgress[scaleEachInRow].dimensionsSmall).width * scaleFactor;
        rowInProgress[scaleEachInRow].scaledHeight = scaledHeight;
        rowInProgress[scaleEachInRow].scaledWidth = scaledWidth;
        runningWidth += scaledWidth;
      }

      // when over limit, calculate scaling factor, and add to structure of rows
      const runningMarginWidth = marginSize * (imagesInRow - 1);
      // unused?
      // const runningWidthIncludingMargins = runningWidth + runningMarginWidth

      if (runningWidth > availableWidth - runningMarginWidth) {
        const iOversizedRatio = runningWidth / (availableWidth - runningMarginWidth);
        const rowHeight = shortestImageInRowHeight / iOversizedRatio;
        for (let iFinalScaleEachInRow = 0; iFinalScaleEachInRow < rowInProgress.length; iFinalScaleEachInRow++) {
          rowInProgress[iFinalScaleEachInRow].scaledHeight = rowInProgress[iFinalScaleEachInRow].scaledHeight / iOversizedRatio;
          rowInProgress[iFinalScaleEachInRow].scaledWidth = rowInProgress[iFinalScaleEachInRow].scaledWidth / iOversizedRatio;
        }
        // add all to row and reset
        holdingRows.push(rowInProgress);
        rowInProgress = [];
        runningWidth = 0;
        imagesInRow = 0;
        // unused?

        rowHeights.push(rowHeight);
      } else {
        // put left over images into a row somehow? or squeeze into previous?
        if (resultNo === pictures.length - 1) {
          // we're at the end
          holdingRows.push(rowInProgress);
          // to do, not 300 but it's actual height
          rowHeights.push(rowInProgress[0].scaledHeight); // default
        }
      }
    }
    setRowHeights(rowHeights);
    setRows(holdingRows);
  };
  React.useEffect(() => {
    // if we have a width (div has rendered) and results, and importantly the width is different (don't recalculate unnecessarily)
    if ((bounds?.width ?? 0) > 0 && pictures) {
      calculateJustifiedImageGallery();
    }
  }, [bounds?.width]); // took out 'images' as it caused a never ending loop, took out 'width' as I couldn't see it affecting anything

  React.useEffect(() => {
    const debouncedUpdate = debounce(() => {
      if (width) {
        setDebouncedWidth(width);
      }
    }, DEBOUNCE_RESIZE); // Adjust the debounce delay as per your needs (in milliseconds)

    debouncedUpdate();
    return debouncedUpdate.clear;
  }, [width]);
  return <div ref={ref} id="justified-gallery" className="spacing-between-sections-above" data-sentry-component="JustifiedGallery" data-sentry-source-file="JustifiedGallery.tsx">
			{/* display each row */}
			{pictures.length > 0 && rows.map((row, rowIndex) => {
      return <div key={rowIndex} className="justified-row" style={{
        height: `${rowHeights[rowIndex]}px`
      }}>
							{/* and every image in each row*/}
							{row.map((result, rowResultIndex) => {
          return <img title={altText} alt={altText} key={rowResultIndex} src={getUploadThumbPath(result.id, size)} width={`${result.scaledWidth}px`} height={`100%`} onClick={() => onImageClick?.(result.id)} ref={refs[result.index]} className="gallery-image-tile" />;
        })}
						</div>;
    })}
		</div>;
};
export default JustifiedGallery;