import { Box, Button, Container, Grid, Overlay, SimpleGrid, Text, Title, Tooltip, Rating } from '@mantine/core';
import { ArrowRight } from 'tabler-icons-react';
import Link from 'next/link';
import Head from 'next/head';
import { NextSeo } from 'next-seo';
import NextImage from 'next/image';
import * as React from 'react';
import * as Types from 'declarations';
import { getGuideRoute, getUploadThumbPath } from 'lib/display';
import { convertSimpleToFrench, getDefaultImage, homePageTripToTripCard } from 'lib/helper';
import * as RedisUtil from 'lib/redis';
import PageTemplate from 'components/PageTemplate';
import AccountNudge from 'components/AccountNudge';
// import TripsCarousel from 'components/HomePage/TripsCarousel'
import TrekCard from 'components/TrekCard';
import ArticlesCarousel from 'components/HomePage/ArticlesCarousel';
import TripMap from 'components/HomePage/TripMap';
import HTMLContent from 'components/HTMLContent';
import { withVersion } from '../lib/withVersion';
import JustifiedGallery from 'components/TrekPage/components/JustifiedGallery';
const HeroCarousel = ({
  version,
  imageURLs,
  guideCount,
  expeditionCount,
  tripCount,
  countryCount,
  reviewCount
}: {
  version: Types.VERSION;
  imageURLs: string[];
  guideCount: number;
  expeditionCount: number;
  tripCount: number;
  countryCount: number;
  reviewCount: number;
}) => {
  const [activeSlide, setActiveSlide] = React.useState(0);
  const timeout = 3000;
  React.useEffect(() => {
    // three seconds after load, start a three second timer to change the slide
    setTimeout(() => {
      const interval = setInterval(() => {
        setActiveSlide(prev => (prev + 1) % imageURLs.length);
      }, timeout);
      return () => clearInterval(interval);
    }, timeout);
  }, [imageURLs.length]);
  const activeImageBase = imageURLs[activeSlide];
  return <>
			<Head data-sentry-element="Head" data-sentry-source-file="index.tsx">
				{/* <link
     rel="preload"
     as="image"
     href={`${imageBases[activeSlide]}.jpg`}
     type="image/jpeg"
     />
     <link
     rel="preload"
     as="image"
     href={`${imageBases[activeSlide]}.webp`}
     type="image/webp"
     /> */}
				<link rel="preload" as="image" href={`https://www.guidedpeaks.com/cdn-cgi/image/width=1920,format=auto${imageURLs[activeSlide]}`}
      // type="image/webp"
      />
			</Head>
			<div className="wrapper">
				{/* <NextImage
     src={imageURLs[activeSlide]}
     alt={`Slide ${activeSlide + 1}`}
     fill
     style={{
     	objectFit: 'cover',
     	objectPosition: 'center',
     	transition: 'opacity 0.75s ease-in-out',
     }}
     priority={activeSlide === 0} // Prioritize the first slide for LCP
     /> */}
				{activeImageBase && <picture>
						{/* <source srcSet={`${activeImageBase}.webp`} type="image/webp" />
       <source srcSet={`${activeImageBase}.jpg`} type="image/jpeg" /> */}

						<source srcSet={`https://www.guidedpeaks.com/cdn-cgi/image/width=1920,format=auto${activeImageBase}`} media="(min-width: 1200px)"
        // type="image/jpeg"
        />
						<source
        // srcSet={`${activeImageBase}.jpg?width=1280&quality=75`}
        srcSet={`https://www.guidedpeaks.com/cdn-cgi/image/width=1280,format=auto${activeImageBase}`} media="(min-width: 768px)"
        // type="image/jpeg"
        />
						<source
        // srcSet={`${activeImageBase}.jpg?width=768&quality=75`}
        srcSet={`https://www.guidedpeaks.com/cdn-cgi/image/width=768,format=auto${activeImageBase}`} media="(max-width: 768px)"
        // type="image/jpeg"
        />

						<NextImage
        // src={`${activeImageBase}.jpg`}
        // src={`${activeImageBase}.jpg?width=1920&quality=75`}
        src={`https://www.guidedpeaks.com/cdn-cgi/image/width=1920,format=auto${activeImageBase}`} alt={``} objectFit="fill" layout="fill"
        // fill
        style={{
          objectFit: 'cover',
          objectPosition: 'center',
          transition: 'opacity 0.75s ease-in-out'
        }}
        // priority={true}
        priority={activeSlide === 0} // Prioritize the first slide for LCP
        />
					</picture>}
				<Overlay color="#000" opacity={0.6} zIndex={1} data-sentry-element="Overlay" data-sentry-source-file="index.tsx" />
				<div className={'inner'}>
					<Title className={'title'} mb={0} data-sentry-element="Title" data-sentry-source-file="index.tsx">
						{version === Types.VERSION.PEAKS ? <>
								Mountaineering.
								<br />
								Find expeditions - Climb mountains
							</> : 'Find amazing treks, create incredible memories'}
					</Title>
					<Container style={{
          display: 'flex',
          color: '#ddd'
        }} data-sentry-element="Container" data-sentry-source-file="index.tsx">
						<Box
          // size="lg"
          className={'description'} style={{
            display: 'flex',
            margin: '0 auto',
            fontSize: 'larger'
          }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
							{version === Types.VERSION.PEAKS ? <div style={{
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center'
            }}>
									{/* <Title order={2} style={{ textAlign: 'center' }}>
          <u>{guideCount.toLocaleString()} guides</u>, with{' '}
          <u>{expeditionCount.toLocaleString()} expeditions</u> on{' '}
          <u>{tripCount} mountains</u>
          </Title> */}
									<Title order={2} style={{
                textAlign: 'center'
              }} mt="xs">
										Mountains, Guides, Expeditions - Curated by and for
										mountaineers
									</Title>
									{version === Types.VERSION.PEAKS && <Box mt="sm" style={{
                marginBottom: 0,
                textAlign: 'center'
              }}>
											{/* Explore mountains, Connect with guides to make your dreams happen.
            <br />
            Guided Peaks is made by mountaineers for mountaineers.
            <br />
            Connecting you with guides around the world. */}
										</Box>}
									<Box style={{
                fontSize: 'x-large',
                listStyle: 'none',
                textAlign: 'center'
              }} mt="xl" mb="xl">
										<Text>
											Explore{' '}
											<Link href="/expeditions">
												{expeditionCount.toLocaleString()} Expeditions
											</Link>{' '}
											in{' '}
											<Link href="/countries">
												{countryCount.toLocaleString()} Countries
											</Link>
										</Text>
										<Text>
											Compare{' '}
											<Link href="/guides">
												{guideCount.toLocaleString()} Guides
											</Link>{' '}
											with{' '}
											<Link href="/guides">
												{reviewCount.toLocaleString()} Reviews
											</Link>
										</Text>

										<Text>
											Read <Link href="/articles">Articles</Link> to best
											prepare
										</Text>
									</Box>
								</div> : <ul>
									<li>Explore treks around the world</li>
									<li>Filter treks by country, difficulty, cost, and more</li>
								</ul>}
						</Box>
					</Container>
					<div className={'controls'}>
						<Link href={'/expeditions'} data-sentry-element="Link" data-sentry-source-file="index.tsx">
							<Button size="lg" radius="md" rightIcon={<ArrowRight size={20} />} data-sentry-element="Button" data-sentry-source-file="index.tsx">
								Explore{' '}
								{version === Types.VERSION.PEAKS ? 'Expeditions' : 'Treks'}
							</Button>
						</Link>
						{version === Types.VERSION.PEAKS && <Link href={'/guides'}>
								<Button size="lg" radius="md" variant={'light'} rightIcon={<ArrowRight size={20} />}>
									Compare Guides
								</Button>
							</Link>}
					</div>
				</div>
			</div>
		</>;
};
export type HomePageProps = {
  // treks: Types.App.HomePageTrip[]
  version: Types.VERSION;
  guideCount: number;
  expedCount: number;
  countryCount: number;
  reviewCount: number;
  guideInfo: {
    logoId: number;
    slug: string;
    name: string;
  }[];
  latestArticles: Types.API.Article[];
  carouselTrips: Types.App.Types.CarouselCollection[];
  continentSummaries: Types.App.Types.ContinentSummary[];
  peakMeta: Types.App.Types.PeakMeta;
  trekMeta: Types.App.Types.TrekMeta;
  peakLocations: Types.App.Types.HomePagePeakLocation[];
  uniqueGuideLanguages: string[];
  imageUrls: string[];
  summitPictures: Types.DB.Models.UploadAttributes[];
  mountainReviews: Types.App.Types.MountainReview[];
};
const HomePage = ({
  version,
  guideCount,
  expedCount,
  countryCount,
  reviewCount,
  guideInfo,
  latestArticles,
  carouselTrips,
  continentSummaries,
  peakMeta,
  trekMeta,
  peakLocations,
  uniqueGuideLanguages,
  imageUrls,
  summitPictures,
  mountainReviews
}: HomePageProps) => {
  const seo = React.useMemo(() => {
    return {
      title: version === Types.VERSION.TREKKING ? 'Find guided treks - Guided Trekking' : 'Find Mountaineering Expeditions & Guides - Guided Peaks',
      keywords: 'mountaineering,mountain climbing,expeditions,guided expeditions,guided climbing,guided mountaineering',
      description: version === Types.VERSION.TREKKING ? 'Curated guide of treks for hikers, walkers, & trekkers. Information for each route to compare & find your best next route.' : 'Explore the best mountain climbing & mountaineering expeditions around the world. Join guided climbs, discover mountains, & prepare for your next adventure.',
      canonical: `https://www.guided${version === Types.VERSION.TREKKING ? 'trekking' : 'peaks'}.com`,
      siteName: version === Types.VERSION.TREKKING ? 'Guided Trekking' : 'Guided Peaks',
      images: [{
        url: getDefaultImage(version),
        type: 'image/jpeg'
      }],
      twitter: {
        cardType: 'summary_large_image',
        // Use 'summary' if no image is available
        site: '@_14335698',
        // Replace with your Twitter handle
        handle: '@_14335698',
        // Optional: Handle of the content creator
        image: getDefaultImage(version) // Twitter Card image
      }
    };
  }, [version]);
  const headerMT = 'xl';
  const headerMB = 'lg';
  return <>
			<NextSeo title={seo.title} description={seo.description} canonical={seo.canonical} openGraph={{
      url: seo.canonical,
      title: seo.title,
      description: seo.description,
      images: seo.images,
      siteName: seo.siteName,
      type: 'website',
      locale: 'en_US'
    }} additionalMetaTags={[{
      name: 'keywords',
      content: seo.keywords
    }]} data-sentry-element="NextSeo" data-sentry-source-file="index.tsx" />

			<PageTemplate isFullWidth={true} isFullHeight={false} isWithoutTopMargin={true} data-sentry-element="PageTemplate" data-sentry-source-file="index.tsx">
				<div id="homepage">
					<HeroCarousel version={version} imageURLs={imageUrls} guideCount={guideCount} countryCount={countryCount} expeditionCount={version === Types.VERSION.PEAKS ? peakMeta.expedCount : -1} tripCount={version === Types.VERSION.PEAKS ? peakMeta.tripCount : trekMeta.tripCount} reviewCount={reviewCount} data-sentry-element="HeroCarousel" data-sentry-source-file="index.tsx" />

					<div style={{
          maxWidth: '1100px',
          margin: '0 auto',
          paddingLeft: '8px',
          paddingRight: '8px'
        }}>
						{version === Types.VERSION.PEAKS && <>
								<div className="how-it-works-section">
									<Title order={2} mt={headerMT} mb={headerMB}>
										For mountaineers:
									</Title>
									<div className="how-it-works-row">
										<div className="image-container">
											<NextImage src={`https://www.guidedpeaks.com/cdn-cgi/image/width=1920,format=auto${'/static/homepage/expeditions.png'}`} alt="Search for mountains" width={300} height={225} />
										</div>
										<div className="content">
											<Title order={3}>Find Your Next Mountain</Title>
											<p>
												Search and filter{' '}
												<strong>
													{(version === Types.VERSION.PEAKS ? peakMeta.tripCount : trekMeta.tripCount).toLocaleString()}{' '}
													mountains
												</strong>{' '}
												by height, difficulty, season, and location to find your
												perfect climb.
											</p>
											<Link href="/expeditions">
												<Button size="md" radius="md" mt="lg" rightIcon={<ArrowRight size={20} />}>
													Explore all{' '}
													{version === Types.VERSION.PEAKS ? 'Mountains' : 'Treks'}
												</Button>
											</Link>
										</div>
									</div>
									<div className="how-it-works-row">
										<div className="image-container">
											<NextImage src={`https://www.guidedpeaks.com/cdn-cgi/image/width=1920,format=auto${'/static/homepage/guide-directory.png'}`} alt="Compare guides" width={300} height={225} />
										</div>
										<div className="content">
											<Title order={3}>Compare Guides</Title>
											<p>
												Review{' '}
												<strong>{guideCount.toLocaleString()} guides</strong>,
												compare prices, and read verified reviews to choose the
												right expedition partner.
											</p>
											<Link href="/guides">
												<Button size="md" radius="md" mt="lg" rightIcon={<ArrowRight size={20} />}>
													Compare Guides
												</Button>
											</Link>
										</div>
									</div>
									<div className="how-it-works-row">
										<div className="image-container">
											<NextImage src={`https://www.guidedpeaks.com/cdn-cgi/image/width=1920,format=auto${'/static/homepage/guide-reviews.png'}`} alt="Check reviews" width={300} height={225} />
										</div>
										<div className="content">
											<Title order={3}>Check reviews</Title>
											<p>
												Check{' '}
												<strong>
													{reviewCount.toLocaleString()} guide reviews
												</strong>
												, to see how each guide handles each mountain.
											</p>
										</div>
									</div>

									<Title order={2} mt={headerMT} mb={headerMB}>
										For guides:
									</Title>
									<div className="how-it-works-row">
										<div className="image-container">
											<NextImage src={`https://www.guidedpeaks.com/cdn-cgi/image/width=1920,format=auto${'/static/homepage/guide-profile.png'}`} alt="Create guide profile" width={300} height={225} />
										</div>
										<div className="content">
											<Title order={3}>Create Your Profile</Title>
											<p>
												Showcase your expertise, <strong>certifications</strong>
												, and experience to potential clients.
											</p>
											<Link href="/guide">
												<Button size="md" radius="md" mt="lg" rightIcon={<ArrowRight size={20} />}>
													Guide dashboard
												</Button>
											</Link>
										</div>
									</div>
									<div className="how-it-works-row">
										<div className="image-container">
											<NextImage src={`https://www.guidedpeaks.com/cdn-cgi/image/width=1920,format=auto${'/static/homepage/guide-trips.png'}`} alt="List expeditions" width={300} height={225} />
										</div>
										<div className="content">
											<Title order={3}>List Your Expeditions</Title>
											<p>
												Add your mountain expeditions with details of what
												differitiates your trip from other agencies; climber to
												guide ratio, trip length, group size, discounts, and add
												any videos showing the experience.
											</p>
										</div>
									</div>
								</div>
							</>}
						<Title order={2} mt={headerMT} mb={headerMB} data-sentry-element="Title" data-sentry-source-file="index.tsx">
							{version === Types.VERSION.PEAKS ? 'Explore Mountain Climbing Expeditions' : 'Treks you can join - find a trip'}
						</Title>

						<div style={{
            textAlign: 'center',
            fontSize: 'large'
          }}></div>

						<Grid data-sentry-element="Grid" data-sentry-source-file="index.tsx">
							<Grid.Col span={12} sm={6} className="trip-map-col" data-sentry-element="unknown" data-sentry-source-file="index.tsx">
								<TripMap locations={peakLocations as {
                latitude: number;
                longitude: number;
                height: number | null;
              }[]} version={version} data-sentry-element="TripMap" data-sentry-source-file="index.tsx" />
							</Grid.Col>
							<Grid.Col span={12} sm={6} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
								<Text data-sentry-element="Text" data-sentry-source-file="index.tsx">
									<Box data-sentry-element="Box" data-sentry-source-file="index.tsx">
										{version === Types.VERSION.PEAKS ? <>
												<p>
													For each mountain, see what guides offer expeditions
													and compare prices. Then check out their guide
													profiles for reviews and contact details.
												</p>
											</> : <>
												<p>
													Guided Trekking is for hikers, looking for their next
													great trek.
												</p>
												<p>
													It saves you time searching all over the internet,
													first for information on what trek is best - and then
													the for guides.
												</p>
												<p>
													For each trek, see what guides offer trips and compare
													prices. Then check out their guide profiles for
													reviews and contact details.
												</p>
											</>}
									</Box>
									<div style={{
                  display: 'flex',
                  textAlign: 'left',
                  justifyContent: 'center'
                }}>
										{version === Types.VERSION.PEAKS ? <ul style={{
                    fontSize: 'larger',
                    margin: '0 auto'
                  }}>
												{/* // todo: make unit agnostic */}
												<li>
													Climb mountains {peakMeta.lowestPeak.toLocaleString()}{' '}
													- {peakMeta.highestPeak.toLocaleString()} meters.
												</li>
												<li>
													Mixed difficulties:{' '}
													{version === Types.VERSION.PEAKS && <>
															{peakMeta.difficulties.map((diff, idxDiffs) => <span key={idxDiffs}>
																	{idxDiffs > 0 && ', '}
																	{convertSimpleToFrench(diff).abbreviation}
																</span>)}
														</>}
													{version as Types.VERSION === Types.VERSION.TREKKING && <>
															{trekMeta.difficulties.lowest}/10 -{' '}
															{trekMeta.difficulties.highest}/10
														</>}
												</li>
												<li>
													On {continentSummaries.length} continents, and{' '}
													{continentSummaries.reduce((total, {
                        countries
                      }) => total + countries.length, 0)}{' '}
													countries
												</li>
											</ul> : <ul>
												<li>
													Max elevations from{' '}
													{trekMeta.shortestDays.toLocaleString()} days to -{' '}
													{trekMeta.longestDays.toLocaleString()} days long
												</li>
												<li>
													Mixed difficulties: {trekMeta.difficulties.lowest}/10
													to {trekMeta.difficulties.highest}/10
												</li>
												<li>
													On {continentSummaries.length} continents, and{' '}
													{continentSummaries.reduce((total, {
                        countries
                      }) => total + countries.length, 0)}{' '}
													<Link href="/countries">Countries</Link>
												</li>
											</ul>}
									</div>
								</Text>
								<Box mt="xl" style={{
                textAlign: 'center'
              }} data-sentry-element="Box" data-sentry-source-file="index.tsx">
									<Link href="/expeditions" data-sentry-element="Link" data-sentry-source-file="index.tsx">
										<Button size="md" radius="md" data-sentry-element="Button" data-sentry-source-file="index.tsx">
											Explore all{' '}
											{version === Types.VERSION.PEAKS ? 'Mountains' : 'Treks'}
										</Button>
									</Link>
								</Box>
								<br />
								{version === Types.VERSION.PEAKS && <>
										<Text>
											There are{' '}
											{version === Types.VERSION.PEAKS ? 'expeditions' : 'treks'}{' '}
											in {countryCount} countries.
										</Text>{' '}
										{version === Types.VERSION.PEAKS && <Text>
												Check out out the{' '}
												<Link href="/countries">Countries</Link> pages for
												detailed information on each region.
											</Text>}
									</>}
							</Grid.Col>
						</Grid>

						{/* Mountain Reviews Section */}
						{version === Types.VERSION.PEAKS && mountainReviews && mountainReviews.length > 0 && <>
									<Title order={2} mt={headerMT} mb={headerMB}>
										Climber Reviews
									</Title>
									<Text mb="lg">
										Read relevant reviews from experiences climbing peaks
										you&apos;re interested in.
									</Text>
									<div className="mountain-reviews-section">
										{mountainReviews.map((review, index) => {
                const parts = review.reviewText.toLowerCase().split(review.mountainName.toLowerCase());
                if (parts.length < 2) return null;

                // Get one sentence before and after the mountain name
                const beforeSentences = parts[0].split(/[.!?]+\s/).filter(Boolean);
                const afterSentences = parts[1].split(/[.!?]+\s/).filter(Boolean);
                const beforeText = beforeSentences.length > 0 ? beforeSentences[beforeSentences.length - 1] : '';
                const afterText = afterSentences.length > 0 ? afterSentences[0] : '';
                return <div key={index} className="mountain-review-card">
													<div className="review-content">
														<div className="review-meta">
															<Rating value={review.reviewPercent / 20} fractions={2} readOnly size="sm" style={{
                        marginBottom: '4px'
                      }} />
															<Text size="sm" color="dimmed">
																<Link href={getGuideRoute({
                          slug: review.companySlug
                        } as Types.API.GuideCompany)}>
																	{review.companyName}
																</Link>
															</Text>
														</div>
														<Text size="lg" style={{
                      fontStyle: 'italic',
                      borderTop: '1px solid #eee',
                      paddingTop: '12px'
                      // marginTop: '12px',
                    }}>
															&ldquo;{beforeText}{' '}
															<strong>{review.mountainName}</strong> {afterText}
															.&rdquo;
														</Text>
													</div>
												</div>;
              })}
									</div>
								</>}

						{/* // todo: make this for trekking too */}
						{version === Types.VERSION.PEAKS && <div className="homepage-guides-overview">
								<Title order={2} mt={headerMT} mb={headerMB} id="findaguide">
									Find a mountaineering guide
								</Title>
								<Text mb="lg">
									We have curated <strong>{guideCount} Guides</strong>. Find
									which is right for you.
								</Text>

								<SimpleGrid cols={4} spacing="lg" mt={'xl'} mb={'xl'} breakpoints={[{
              maxWidth: 'md',
              cols: 3,
              spacing: 'md'
            }, {
              maxWidth: 'sm',
              cols: 2,
              spacing: 'sm'
            }, {
              maxWidth: 'xs',
              cols: 2,
              spacing: 'sm'
            }]}>
									{guideInfo.filter((_, idxGuides) => idxGuides < 12).map((guideInfo, idxGuideLogos) => <div key={idxGuideLogos} style={{
                height: '100px',
                display: 'flex'
              }} className="guide-overview-item">
												<Link href={getGuideRoute(guideInfo as unknown as Types.API.GuideCompany)} style={{
                  display: 'contents'
                }}>
													<Tooltip label={guideInfo.name} inline withArrow>
														<NextImage src={getUploadThumbPath(guideInfo.logoId, 'medium')} alt={guideInfo.name} width={160} height={80} style={{
                      maxHeight: '100%',
                      width: 'auto',
                      borderRadius: '8px',
                      maxWidth: '100%',
                      height: 'auto',
                      margin: '0 auto'
                    }} />
													</Tooltip>
												</Link>
											</div>)}
								</SimpleGrid>
								<div style={{
              textAlign: 'center'
            }}>
									{/* <Text size="xl" mt={headerMT} mb={headerMB}>
          Find one offering the mountains you&apos;re interested in,
          and with a profile that resonates with you:
          </Text> */}
									<Link href="/guides">
										<Button size="md" mt="xl" radius="md">
											Compare Guides
										</Button>
									</Link>
								</div>
							</div>}

						{version === Types.VERSION.PEAKS && summitPictures && summitPictures.length > 0 && <>
									<Title order={2} mt={headerMT} mb={headerMB}>
										Summit Success Stories
									</Title>
									<Text mb="lg">
										Join successful expeditions to the world&apos;s greatest
										peaks. Here are some recent summit achievements from the
										community.
									</Text>
									<JustifiedGallery pictures={summitPictures} size="medium" altText="Summit success pictures" />
								</>}

						{version === Types.VERSION.PEAKS && <>
								<Box pt="xl" mb="xl" style={{
              margin: '0 auto'
            }}>
									<div style={{
                maxWidth: '360px',
                margin: '0 auto'
              }}>
										<AccountNudge variant="modal" version={version} meta={{
                  guideCount,
                  reviewCount
                }} />
									</div>
								</Box>
							</>}
					</div>
				</div>
			</PageTemplate>
		</>;
};
export const getServerSideProps = withVersion(async (context: {
  version: Types.VERSION;
}) => {
  const homePageData = await RedisUtil.Data.HomePageData.get({
    version: context.version
  }
  // false,
  // true,
  );
  const {
    trips,
    guideInfo,
    guideCount,
    carouselTrips,
    continentSummaries,
    peakMeta,
    trekMeta,
    peakLocations,
    uniqueGuideLanguages,
    latestArticles,
    reviewCount,
    summitPictures,
    mountainReviews
  } = homePageData;
  const uniqueCountries = [...new Set(trips.flatMap(trip => trip.countries))];
  const images = context.version === Types.VERSION.PEAKS ? ['405', '903', '1202', '1203', '1322', '1600', '1835', '1839'] : ['1', '59', '69', '730', '732', '1118', '1142', '1146', '1469'];
  const imageUrls = images.map(file => `/static/homepage-carousel/${context.version === Types.VERSION.PEAKS ? 'peaks' : 'trekking'}/${file}.jpg`);
  return {
    props: {
      guideCount,
      countryCount: uniqueCountries.length,
      guideInfo,
      version: context?.version,
      latestArticles,
      carouselTrips,
      continentSummaries,
      peakMeta,
      trekMeta,
      peakLocations,
      uniqueGuideLanguages,
      imageUrls,
      // base,
      summitPictures,
      reviewCount,
      mountainReviews
    }
  };
});
export default HomePage;